export const enum EventType {
  ComponentAddedToStage = 'componentAddedToStage',
  ConnectedComponentPasted = 'connectedComponentPasted',
}

export const enum ComponentType {
  SearchBox = 'wixui.SearchBox',
  TPA = 'wysiwyg.viewer.components.tpapps.TPASection',
}

export const enum ControllerType {
  SearchApp = 'SearchAppController',
}

export const enum WixCodeRole {
  SearchBox = 'searchBox',
  SearchResults = 'searchResults',
}
