import { getSearchResults } from '@wix/client-search-sdk/dist/src/sdk/mockApi/searchResults';
import { SuggestionsParser } from '@wix/client-search-sdk/dist/src/sdk/parsers';
import {
  SearchDocumentType,
  ISuggestionsResponse,
} from '@wix/client-search-sdk';
import { IEditorSDKWrapper, IComponentRef } from '@wix/platform-editor-helpers';

export async function setDemoSuggestions(
  editor: IEditorSDKWrapper,
  componentRef: IComponentRef,
) {
  /**
   * NOTE: add base url so suggestion will work in editor
   */
  const parser = new SuggestionsParser('', 'en');

  const results = [
    ...getSearchResults({
      query: '',
      documentType: SearchDocumentType.Pages,
      limit: 3,
      skip: 0,
    }),
    ...getSearchResults({
      query: '',
      documentType: SearchDocumentType.Products,
      limit: 3,
      skip: 0,
    }),
  ];

  const documents: ISuggestionsResponse['suggestions'] = parser.parse(results);

  const suggestions = {
    query: 'search term...',
    searchAllUrl: '/search-term',
    documents: documents.map(document => {
      return {
        ...document,
        url: '/document-type',
      };
    }),
  };

  await editor.SDK.components.data.update(editor.token, {
    componentRef,
    data: {
      suggestions,
    },
  });
}
