import { getSearchResultsHeaderComponentDefinition } from './componentDefinitions';
import { ComponentType } from './types';
import {
  getComponent,
  IEditorSDKWrapper,
  IPageRef,
  IComponentRef,
} from '@wix/platform-editor-helpers';

export async function getSearchResultsComponent(
  editor: IEditorSDKWrapper,
): Promise<IComponentRef> {
  return getComponent(
    editor,
    ({ componentType, componentData }) =>
      componentData &&
      componentData.appDefinitionId === editor.appDefinitionId &&
      componentType === ComponentType.TPA,
  );
}

export async function getSearchResultsPage(
  editor: IEditorSDKWrapper,
): Promise<IPageRef> {
  return editor.SDK.components.getPage(editor.token, {
    componentRef: await getSearchResultsComponent(editor),
  });
}

// NOTE: search results layout https://zpl.io/VOxkPyY
// https://docs.google.com/presentation/d/1cxgiYIkRgOVdy-xUv090CfDSdapppE9-IvGejs2qHhE/edit#slide=id.g5f497222a2_4_39
const SEARCH_RESULTS_HEADER_LAYOUT = {
  width: 600,
  height: 43,
  marginTop: 52,
  marginBottom: 52,
};
const SEARCH_RESULTS_LAYOUT = {
  marginTop: 16,
};
export async function addSearchResultsHeader(
  editor: IEditorSDKWrapper,
  {
    searchResultsPageRef,
    searchResultsRef,
  }: {
    searchResultsPageRef: IPageRef;
    searchResultsRef: IComponentRef;
  },
): Promise<IComponentRef> {
  const searchResultsPageLayout = await editor.SDK.components.layout.get(
    editor.token,
    {
      componentRef: searchResultsPageRef,
    },
  );

  const headerLayout = {
    x: Math.round(
      searchResultsPageLayout.bounding.width / 2 -
        SEARCH_RESULTS_HEADER_LAYOUT.width / 2,
    ),
    y: SEARCH_RESULTS_HEADER_LAYOUT.marginTop,
    width: SEARCH_RESULTS_HEADER_LAYOUT.width,
    height: SEARCH_RESULTS_HEADER_LAYOUT.height,
  };
  // NOTE: magic calculation to make bottom margin 52px (https://zpl.io/VOxkPyY)
  const headerMarginBottomFixed =
    SEARCH_RESULTS_HEADER_LAYOUT.marginBottom -
    SEARCH_RESULTS_LAYOUT.marginTop -
    9;

  await editor.SDK.components.layout.update(editor.token, {
    componentRef: searchResultsRef,
    layout: {
      x: 0,
      y: headerLayout.y + headerLayout.height + headerMarginBottomFixed,
    },
  });

  return editor.SDK.components.add(editor.token, {
    pageRef: searchResultsPageRef,
    componentDefinition: getSearchResultsHeaderComponentDefinition({
      // TODO: use translation for this text
      text: 'Search Results',
      layout: headerLayout,
    }),
  });
}

export async function updateSearchResultsPageData(
  editor: IEditorSDKWrapper,
  { searchResultsPageRef }: { searchResultsPageRef: IPageRef },
): Promise<void> {
  return editor.SDK.pages.data.update(editor.token, {
    pageRef: searchResultsPageRef,
    // interface - https://github.com/wix-private/js-platform-editor-sdk/blob/35499d000316ac4b4921121ee4a6c810589c6387/js/common.ts#L133
    data: {
      hidePage: true,
      indexable: false,
    },
  });
}
