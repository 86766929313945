import { ControllerType, WixCodeRole } from './types';
import { getSearchAppControllerComponentDefinition } from './componentDefinitions';
import {
  getControllerByType,
  getControllersByType,
  IEditorSDKWrapper,
  IPageRef,
  IComponentRef,
  findControllerByType,
} from '@wix/platform-editor-helpers';

export async function connectControllerWithSearchBox(
  editor,
  controllerRef: IComponentRef,
  searchBoxRef: IComponentRef,
): Promise<void> {
  await editor.SDK.controllers.connect(editor.token, {
    controllerRef,
    connectToRef: searchBoxRef,
    role: WixCodeRole.SearchBox,
  });
}

export async function connectControllerWithSearchResults(
  editor,
  controllerRef: IComponentRef,
  searchResultsRef: IComponentRef,
): Promise<void> {
  await editor.SDK.controllers.connect(editor.token, {
    controllerRef,
    connectToRef: searchResultsRef,
    role: WixCodeRole.SearchResults,
  });
}

export async function addSearchAppController(
  editor: IEditorSDKWrapper,
  pageRef?: IPageRef,
): Promise<IComponentRef> {
  if (!pageRef) {
    // NOTE: use header as master page ref, to add controller to all pages.
    // we could not use direct `masterPage` ref, because it will brake logic with popups
    pageRef = await editor.SDK.siteSegments.getHeader();
  }

  return editor.SDK.components.add(editor.token, {
    componentDefinition: getSearchAppControllerComponentDefinition({
      appDefinitionId: editor.appDefinitionId,
    }),
    pageRef,
  });
}

export function getSearchAppController(
  editor: IEditorSDKWrapper,
  pageRef: IPageRef,
): Promise<IComponentRef> {
  return getControllerByType(editor, ControllerType.SearchApp, pageRef);
}

export function getSearchAppControllers(editor: IEditorSDKWrapper) {
  return getControllersByType(editor, ControllerType.SearchApp);
}

export async function isComponentConnected(
  editor: IEditorSDKWrapper,
  componentRef: IComponentRef,
): Promise<boolean> {
  const connections = await editor.SDK.document.controllers.listConnections(
    editor.token,
    { componentRef },
  );
  const controllers = connections.map(connection => connection.controllerRef);

  const controllerRef = await findControllerByType(
    editor,
    controllers,
    ControllerType.SearchApp,
  );

  return !!controllerRef;
}

export async function connectSearchBox(
  editor: IEditorSDKWrapper,
  componentRef: IComponentRef,
) {
  const pageRef: IPageRef = await editor.SDK.components.getPage(editor.token, {
    componentRef,
  });

  let controllerRef = await getSearchAppController(editor, pageRef);

  if (!controllerRef) {
    controllerRef = await addSearchAppController(editor, pageRef);
  }

  await connectControllerWithSearchBox(editor, controllerRef, componentRef);
}
