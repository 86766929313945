import {
  IEditorSDKWrapper,
  getComponentsByType,
  IComponentRef,
} from '@wix/platform-editor-helpers';
import { setDemoSuggestions } from './setDemoSuggestions';

import { getSearchBoxComponentDefinition } from './componentDefinitions';
import { ComponentType } from './types';

const SEARCH_BOX_SIZE = {
  width: 305,
  height: 45,
};

export function getAllSearchBoxes(
  editor: IEditorSDKWrapper,
): Promise<IComponentRef[]> {
  return getComponentsByType(editor, ComponentType.SearchBox);
}

export async function isSearchBox(
  editor: IEditorSDKWrapper,
  componentRef: IComponentRef,
): Promise<boolean> {
  const componentType = await editor.SDK.components.getType(editor.token, {
    componentRef,
  });

  return componentType === ComponentType.SearchBox;
}

export async function addSearchBox(
  editor: IEditorSDKWrapper,
): Promise<IComponentRef> {
  const headerRef = await editor.SDK.siteSegments.getHeader();
  const headerLayout = await editor.SDK.components.layout.get(editor.token, {
    componentRef: headerRef,
  });

  // TODO: handle (headerLayout.bounding.height < searchBoxSize.height) correctly
  const searchBoxLayout = {
    x: headerLayout.bounding.width - SEARCH_BOX_SIZE.width,
    y:
      headerLayout.bounding.height > SEARCH_BOX_SIZE.height
        ? Math.round(
            headerLayout.bounding.height / 2 - SEARCH_BOX_SIZE.height / 2,
          )
        : 0,
    width: SEARCH_BOX_SIZE.width,
    height: SEARCH_BOX_SIZE.height,
  };

  return editor.SDK.components.add(editor.token, {
    pageRef: headerRef,
    componentDefinition: getSearchBoxComponentDefinition({
      layout: searchBoxLayout,
    }),
  });
}

export async function initSearchBoxSuggestions(
  editor: IEditorSDKWrapper,
  componentRef: IComponentRef,
) {
  const data = await editor.SDK.components.data.get(editor.token, {
    componentRef,
  });

  if (data.suggestionsEnabled === undefined) {
    await editor.SDK.components.data.update(editor.token, {
      componentRef,
      data: {
        suggestionsEnabled: false,
      },
    });
  }

  await setDemoSuggestions(editor, componentRef);
}
